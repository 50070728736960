import { useInteractions } from '@floating-ui/react';
import { createContext, useContext } from 'react';

type NumberOrNull = number | null;

export interface FloatingSearchSelectContext {
  activeIndex: NumberOrNull;
  selectedIndex: NumberOrNull;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number) => void;
}

export const floatingSearchSelectContext =
  createContext<FloatingSearchSelectContext | null>(null);

export function useFloatingSearchSelectContext() {
  const context = useContext(floatingSearchSelectContext);

  if (!context) {
    throw new Error('FloatingSearchSelectContext should be defined');
  }

  return context;
}
