import { IntlMessageFormat } from 'intl-messageformat';
import { ReactNode } from 'react';

import type en from './translations/en.json';
import { useTranslation } from './useTranslation';

export type TranslationKeys = keyof typeof en;

interface TranslationsTextProps {
  textKey: TranslationKeys | string;
  values?: Record<
    string,
    ReactNode | Date | ((children: ReactNode) => ReactNode)
  >;
}

export function TranslationsText(props: TranslationsTextProps) {
  const { textKey, values = {} } = props;
  const translation = useTranslation(textKey);

  return <>{new IntlMessageFormat(translation).format(values)}</>;
}
