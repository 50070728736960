import { CSSProperties, MouseEvent, ReactNode } from 'react';

import { Color } from '../../types';

export const BadgeSize = {
  SMALL: 'small',
  LARGE: 'large',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BadgeSize = (typeof BadgeSize)[keyof typeof BadgeSize];

export const BadgeVariant = {
  COLORED_DOT: 'COLORED_DOT',
  COLORED_BACKGROUND: 'COLORED_BACKGROUND',
  CUSTOM_COLOR: 'CUSTOM_COLOR',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type BadgeVariant = (typeof BadgeVariant)[keyof typeof BadgeVariant];

export interface BaseBadgeProps {
  label: ReactNode;
  size?: BadgeSize;

  /**
   * Defines the roundness of the badge.
   *
   * @default "full"
   */
  roundness?: 'full' | 'rounded';

  onDismiss?: (event: MouseEvent) => void;
  className?: string;
}

export interface ColoredDotBadgeProps extends BaseBadgeProps {
  variant: 'COLORED_DOT';
  customColor: CSSProperties['color'];
  dot?: true;
}

export interface ColoredBackgroundBadgeProps extends BaseBadgeProps {
  variant: 'COLORED_BACKGROUND';
  color?: Color;
  dot?: boolean;
}

export interface BadgeCustomColors {
  backgroundColor: CSSProperties['color'];
  textColor: CSSProperties['color'];
}

export interface CustomColorBadgeProps
  extends BaseBadgeProps,
    BadgeCustomColors {
  variant: 'CUSTOM_COLOR';
  dot?: boolean;
}

export type BadgeProps =
  | ColoredBackgroundBadgeProps
  | ColoredDotBadgeProps
  | CustomColorBadgeProps;
