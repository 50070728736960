import clsx from 'clsx';
import { CSSProperties } from 'react';

import { Color } from '../../types';

import { BadgeProps, BadgeSize, BadgeVariant } from './badge.types';

const colors: Record<Color, string> = {
  [Color.neutral]: 'bg-neutral-100 text-neutral-800',
  [Color.alternative]: 'bg-alternative-100 text-alternative-800',
  [Color.danger]: 'bg-danger-100 text-danger-800',
  [Color.primary]: 'bg-primary-100 text-primary-800',
  [Color.success]: 'bg-success-100 text-success-800',
  [Color.warning]: 'bg-warning-100 text-warning-800',
};

const paddings: Record<
  BadgeSize,
  Record<'basic' | 'rounded' | 'remove', string>
> = {
  [BadgeSize.SMALL]: {
    basic: 'px-2.5 py-0.5',
    rounded: 'px-2 py-0.5',
    remove: 'py-0.5 pl-2 pr-0.5',
  },
  [BadgeSize.LARGE]: {
    basic: 'px-3 py-0.5',
    rounded: 'px-2.5 py-0.5',
    remove: 'py-0.5 pl-2.5 pr-1',
  },
};

const whiteBackground = 'bg-white border border-neutral-300';

export function getBadgeClassName(props: BadgeProps) {
  const {
    variant,
    roundness = 'full',
    size = BadgeSize.SMALL,
    onDismiss,
    className,
  } = props;

  const badgeStyle = getBadgeStyle(props);

  const finalColor =
    'color' in props && variant === BadgeVariant.COLORED_BACKGROUND
      ? colors[props.color ?? Color.neutral]
      : whiteBackground;

  const padding = paddings[size];

  return clsx(
    'inline-flex items-center font-semibold',
    size === BadgeSize.LARGE ? 'text-sm' : 'text-xs',
    badgeStyle ? undefined : finalColor,
    roundness === 'rounded'
      ? size === BadgeSize.LARGE
        ? 'rounded-md'
        : 'rounded'
      : 'rounded-full',
    onDismiss
      ? padding.remove
      : roundness === 'full'
        ? padding.rounded
        : padding.basic,
    className,
  );
}

export function getBadgeStyle(props: BadgeProps): CSSProperties | undefined {
  if (
    props.variant === 'CUSTOM_COLOR' &&
    props.backgroundColor &&
    props.textColor
  ) {
    return {
      backgroundColor: props.backgroundColor,
      color: props.textColor,
    };
  }
}
