import { ReactNode, useMemo } from 'react';

import {
  TranslationsContext,
  translationsContext,
  useTranslations,
} from './translations.context';
import { Translations } from './translations.types';

export interface TranslationsProviderProps {
  translations: TranslationsContext['translations'];
  children: ReactNode;
}

export function TranslationsProvider(props: TranslationsProviderProps) {
  const { children, translations } = props;
  const topLevel = useTranslations();

  const value = useMemo<TranslationsContext>(() => {
    const mergeOverride = merge(topLevel, translations);

    return {
      translations: mergeOverride,
    };
  }, [topLevel, translations]);

  return (
    <translationsContext.Provider value={value}>
      {children}
    </translationsContext.Provider>
  );
}

function merge(
  obj: Record<string, Translations>,
  parse: Record<string, Translations>,
): Record<string, Translations> {
  const newObj = obj;

  for (const [lang, values] of Object.entries(parse)) {
    for (const [key, value] of Object.entries(values)) {
      newObj[lang] = {
        ...newObj[lang],
        [key]: value,
      };
    }
  }

  return newObj;
}
