import clsx from 'clsx';
import { CSSProperties } from 'react';

import { Color } from '../../types';

import { BadgeSize, BadgeVariant } from './badge.types';

interface CustomColorColoredDotProps {
  size?: BadgeSize;
  variant: 'COLORED_DOT' | 'CUSTOM_COLOR';
  customColor: CSSProperties['color'];
}

interface ColoredBackgroundDotProps {
  size?: BadgeSize;
  variant: 'COLORED_BACKGROUND';
  color?: Color;
}

type ColoredDotProps = CustomColorColoredDotProps | ColoredBackgroundDotProps;

const dotColors: Record<Color, string> = {
  [Color.neutral]: 'text-neutral-400',
  [Color.alternative]: 'text-alternative-400',
  [Color.danger]: 'text-danger-400',
  [Color.primary]: 'text-primary-400',
  [Color.success]: 'text-success-400',
  [Color.warning]: 'text-warning-400',
};

export function ColoredDot(props: ColoredDotProps) {
  const { size = BadgeSize.SMALL, variant } = props;

  const color = 'color' in props ? props.color : undefined;
  return (
    <svg
      className={clsx(
        'mr-1.5 h-2 w-2',
        size === BadgeSize.LARGE ? '-ml-1' : '-ml-0.5',
        {
          [dotColors[color ?? Color.neutral]]:
            variant === BadgeVariant.COLORED_BACKGROUND && color,
        },
      )}
      fill="currentColor"
      viewBox="0 0 8 8"
      style={{
        color:
          variant === BadgeVariant.COLORED_DOT && 'customColor' in props
            ? props.customColor
            : undefined,
      }}
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
  );
}
