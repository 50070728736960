import { ElementProps } from '@floating-ui/react';
import { KeyboardEvent, useMemo } from 'react';

export function useFloatingEnterAsSelector(callback: () => void): ElementProps {
  const referenceProps = useMemo(
    () => ({
      onKeyDown: (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();

          callback();
        }
      },
    }),
    [callback],
  );

  return useMemo(
    () => ({
      reference: referenceProps,
    }),
    [referenceProps],
  );
}
