import {
  FloatingFocusManager,
  FloatingList,
  useFloating,
  useInteractions,
  useListItem,
} from '@floating-ui/react';
import {
  ChevronDownIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import {
  ChangeEvent,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react';

import { Spinner } from '../elements/spinner/Spinner';
import { Checkbox } from '../forms/basic/Checkbox';
import { Input } from '../forms/basic/Input';
import {
  Help,
  HelpPublicProps,
  InputCorner,
  InputErrorIcon,
  Label,
} from '../forms/basic/common';
import {
  FloatingSearchSelectContext,
  floatingSearchSelectContext,
  useFloatingSearchSelectContext,
} from '../forms/basic/search-select/FloatingSearchSelectContext';
import { inputColor, inputError } from '../forms/basic/utils.common';
import { useInputId } from '../hooks/useInputId';
import { TranslationsText } from '../internationalization/TranslationsText';

import {
  defaultNoResultsHint,
  preventDefault,
} from './defaultSearchSelectUtils';

export type OptionsFilter<OptionType> = (
  query: string,
  options: OptionType[],
) => OptionType[];

export type IsOptionRemovableCallback<OptionType> = (
  option: OptionType,
) => boolean;

export type InternalOption<OptionType> =
  | {
      type: 'option';
      value: string | number;
      label: ReactNode;
      originalValue: OptionType;
      selected: boolean;
      removable: boolean;
    }
  | {
      type: 'create';
      value: '___internal_create___';
      label: ReactNode;
      originalValue: string;
      selected: false;
      removable: false;
    };

export interface FormattedOptionProps<OptionType> {
  option: InternalOption<OptionType>;
  highlightClassName: string;
  showSelected: boolean;
}

export function FormattedOption<OptionType>(
  props: FormattedOptionProps<OptionType>,
) {
  const { option, showSelected, highlightClassName } = props;

  const { getItemProps, activeIndex, selectedIndex, handleSelect } =
    useFloatingSearchSelectContext();

  const { ref, index } = useListItem();
  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;

  return (
    <button
      ref={ref}
      role="option"
      type="button"
      tabIndex={-1}
      aria-selected={isActive && isSelected}
      className={clsx(
        isActive ? highlightClassName : 'text-neutral-900',
        'w-full px-4 py-2 text-left',
      )}
      {...getItemProps({
        onClick: () => handleSelect(index),
      })}
    >
      {showSelected ? (
        <div className="flex items-center">
          {option.type === 'create' ? (
            <PlusCircleIcon className="-ml-0.5 mr-3 h-5 w-5 text-primary-600" />
          ) : (
            <Checkbox
              readOnly
              tabIndex={-1}
              checked={option.selected}
              disabled={!option.removable}
              name={String(option.value)}
            />
          )}

          <div
            className={clsx({
              'text-neutral-500': !option.removable && option.selected,
            })}
          >
            {option.label}
          </div>
        </div>
      ) : option.selected ? null : (
        option.label
      )}
    </button>
  );
}

interface UseSearchSelectInternalsReturn<OptionType> {
  closeList: () => void;
  isListOpen: boolean;
  formattedOptions: Array<InternalOption<OptionType>>;
  activeIndex: number | null;
  showSelected: boolean;
  onSelect: (option: InternalOption<OptionType>) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleXClick: (event: MouseEvent) => void;
  floating: ReturnType<typeof useFloating>;
  interactions: ReturnType<typeof useInteractions>;
  elementsRef: MutableRefObject<Array<HTMLElement | null>>;
  selectedIndex: number | null;
}

interface InternalSearchSelectProps<OptionType>
  extends UseSearchSelectInternalsReturn<OptionType> {
  clearable?: boolean;
  disabled?: boolean;
  loading?: boolean;
  autoFocus?: boolean;
  noResultsHint?: ReactNode;
  help?: HelpPublicProps['help'];
  error?: HelpPublicProps['error'];
  label: ReactNode;
  hiddenLabel?: boolean;
  corner?: ReactNode;
  placeholder?: string;
  searchValue: string;

  formattedSelected?: { value: string | number; label: ReactNode };
  showSelected: boolean;
  hasClearableValue: boolean;
  highlightClassName?: string;
  required?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  name?: string;
  id?: string;

  size?: number;

  inputClassName?: string;
  hasMore?: boolean;
  loadMore?: () => void;
  optionsCount?: number;
}

export function InternalSearchSelect<OptionType>(
  props: InternalSearchSelectProps<OptionType>,
): ReactElement {
  const {
    inputRef,
    isListOpen,
    formattedOptions,
    onSelect,
    handleXClick,
    clearable = false,
    disabled = false,
    required = false,
    loading = false,
    autoFocus = false,
    noResultsHint = defaultNoResultsHint,
    error,
    help,
    label,
    name,
    id,
    hiddenLabel,
    corner,
    placeholder,
    searchValue,
    formattedSelected,
    hasClearableValue,
    highlightClassName = '',
    showSelected = false,
    size,
    inputClassName,
    handleChange,
    activeIndex,
    selectedIndex,
    interactions,
    floating,
    elementsRef,
    hasMore,
    loadMore,
    optionsCount,
  } = props;

  const finalId = useInputId(id, name);

  const finalHighlightClassName =
    highlightClassName ||
    (showSelected ? 'bg-neutral-200' : 'text-white bg-primary-600');

  const handleSelect = useCallback(
    (index: number | null) => {
      if (index !== null && formattedOptions.at(index)) {
        const element = formattedOptions.at(index);

        if (element) {
          return onSelect(element);
        }
      }
    },
    [formattedOptions, onSelect],
  );

  const searchSelectContext = useMemo<FloatingSearchSelectContext>(() => {
    return {
      handleSelect,
      activeIndex,
      selectedIndex,
      getItemProps: interactions.getItemProps,
    };
  }, [handleSelect, activeIndex, interactions.getItemProps, selectedIndex]);

  return (
    <div>
      <Input
        id={finalId}
        name={name}
        inputClassName={inputClassName}
        autoFocus={autoFocus}
        ref={inputRef}
        required={required}
        wrapperRef={props.floating.refs.setReference}
        type="text"
        label={label}
        hiddenLabel={hiddenLabel}
        corner={corner}
        value={searchValue}
        disabled={disabled}
        size={size}
        error={error}
        help={help}
        autoComplete="off"
        onChange={handleChange}
        placeholder={placeholder}
        inlinePlaceholder={
          formattedSelected && !searchValue
            ? formattedSelected.label
            : undefined
        }
        trailingInlineAddon={
          <TrailingTools
            {...{
              loading,
              clearable,
              hasClearableValue,
              disabled,
              handleXClick,
            }}
          />
        }
        {...props.interactions.getReferenceProps()}
      />

      <floatingSearchSelectContext.Provider value={searchSelectContext}>
        {isListOpen && (
          <FloatingFocusManager
            initialFocus={-1}
            context={floating.context}
            modal={false}
          >
            <OptionsList
              optionsCount={optionsCount}
              refs={floating.refs}
              elementsRef={elementsRef}
              floatingStyles={floating.floatingStyles}
              getFloatingProps={interactions.getFloatingProps}
              formattedOptions={formattedOptions}
              noResultsHint={noResultsHint}
              highlightClassName={finalHighlightClassName}
              showSelected={showSelected}
              hasMore={hasMore}
              loadMore={loadMore}
            />
          </FloatingFocusManager>
        )}
      </floatingSearchSelectContext.Provider>
    </div>
  );
}

interface InternalMultiSearchSelectProps<OptionType>
  extends InternalSearchSelectProps<OptionType> {
  selectedBadges?: ReactNode[];
  showSelected: boolean;
}

export function InternalMultiSearchSelect<OptionType>(
  props: InternalMultiSearchSelectProps<OptionType>,
): ReactElement {
  const {
    inputRef,
    isListOpen,
    formattedOptions,
    onSelect,
    handleChange,
    handleXClick,

    clearable = false,
    disabled = false,
    required = false,
    loading = false,
    autoFocus = false,
    noResultsHint,
    error,
    help,
    label,
    hiddenLabel,
    corner,
    placeholder,
    searchValue,
    selectedBadges,
    hasClearableValue,
    id,
    name,
    highlightClassName,
    showSelected = false,
    activeIndex,
    selectedIndex,
    interactions,
    floating,
    elementsRef,
    hasMore,
    loadMore,
    optionsCount,
  } = props;

  const elementId = useInputId(id, name);

  const finalHighlightClassName =
    highlightClassName ||
    (showSelected ? 'bg-neutral-200' : 'text-white bg-primary-600');

  const handleSelect = useCallback(
    (index: number | null) => {
      if (index !== null && formattedOptions.at(index)) {
        const element = formattedOptions.at(index);

        if (element) {
          return onSelect(element);
        }
      }
    },
    [formattedOptions, onSelect],
  );

  const searchSelectContext = useMemo<FloatingSearchSelectContext>(() => {
    return {
      handleSelect,
      activeIndex,
      selectedIndex,
      getItemProps: interactions.getItemProps,
    };
  }, [handleSelect, activeIndex, interactions.getItemProps, selectedIndex]);

  return (
    <div className="flex flex-col">
      <div className="flex items-baseline justify-between gap-2">
        <Label
          id={elementId}
          text={label}
          hidden={hiddenLabel}
          required={required}
          disabled={disabled}
        />
        <InputCorner>{corner}</InputCorner>
      </div>
      <label
        ref={floating.refs.setReference}
        htmlFor={elementId}
        className={clsx(
          'border px-3 py-2 focus-within:ring-1',
          'relative flex flex-1 flex-row text-base shadow-sm sm:text-sm',
          'rounded-md',
          {
            'mt-1': !hiddenLabel || corner,
            [inputColor]: !error,
            [inputError]: error,
            'bg-neutral-50 text-neutral-500': disabled,
            'bg-white': !disabled,
          },
        )}
      >
        <div className="flex flex-1 flex-row flex-wrap gap-1.5">
          {selectedBadges}
          <input
            id={elementId}
            name={name}
            ref={inputRef}
            type="text"
            value={searchValue}
            size={Math.max(5, placeholder?.length || 0, searchValue.length)}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete="off"
            onChange={handleChange}
            placeholder={placeholder}
            {...interactions.getReferenceProps()}
            className={clsx(
              {
                'flex-1 border-none p-0 focus:outline-none focus:ring-0 sm:text-sm':
                  true,
                'bg-neutral-50 text-neutral-500': disabled,
              },
              error ? 'placeholder-danger-300' : 'placeholder-neutral-400',
            )}
          />
        </div>
        <TrailingTools
          loading={loading}
          clearable={clearable}
          hasClearableValue={hasClearableValue}
          hasError={Boolean(error)}
          disabled={disabled}
          handleXClick={handleXClick}
        />
      </label>
      <Help error={error} help={help} />

      <floatingSearchSelectContext.Provider value={searchSelectContext}>
        {isListOpen && (
          <FloatingFocusManager
            initialFocus={-1}
            returnFocus={false}
            context={props.floating.context}
            modal={false}
          >
            <OptionsList
              optionsCount={optionsCount}
              refs={floating.refs}
              elementsRef={elementsRef}
              floatingStyles={floating.floatingStyles}
              getFloatingProps={interactions.getFloatingProps}
              formattedOptions={formattedOptions}
              noResultsHint={noResultsHint}
              highlightClassName={finalHighlightClassName}
              showSelected={showSelected}
              hasMore={hasMore}
              loadMore={loadMore}
            />
          </FloatingFocusManager>
        )}
      </floatingSearchSelectContext.Provider>
    </div>
  );
}

type OptionsListProps<OptionType> = Pick<
  UseSearchSelectInternalsReturn<OptionType>,
  'formattedOptions' | 'elementsRef'
> &
  Pick<
    InternalSearchSelectProps<OptionType>,
    'noResultsHint' | 'highlightClassName' | 'optionsCount'
  > &
  Pick<
    UseSearchSelectInternalsReturn<OptionType>['floating'],
    'refs' | 'floatingStyles'
  > &
  Pick<
    UseSearchSelectInternalsReturn<OptionType>['interactions'],
    'getFloatingProps'
  > & {
    showSelected: boolean;
    hasMore?: boolean;
    loadMore?: () => void;
  };

function OptionsList<OptionType>(props: OptionsListProps<OptionType>) {
  const {
    formattedOptions,
    noResultsHint = defaultNoResultsHint,
    highlightClassName = 'text-white bg-primary-600',
    showSelected,
    refs,
    floatingStyles,
    getFloatingProps,
    elementsRef,
    hasMore,
    loadMore,
    optionsCount,
  } = props;

  const finalFormattedOptions = showSelected
    ? formattedOptions
    : formattedOptions.filter((option) => !option.selected);

  return (
    <div
      style={floatingStyles}
      ref={refs.setFloating}
      {...getFloatingProps()}
      className="z-20 max-h-60 w-full cursor-default overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm"
    >
      {finalFormattedOptions.length === 0 ? (
        <div className="p-2">{noResultsHint}</div>
      ) : (
        <FloatingList elementsRef={elementsRef}>
          {finalFormattedOptions.map((option) => (
            <FormattedOption
              key={option.value}
              option={option}
              highlightClassName={highlightClassName}
              showSelected={showSelected}
            />
          ))}

          {hasMore && loadMore && (
            <button
              type="button"
              onClick={loadMore}
              className="group flex w-full cursor-pointer items-center justify-between border-t border-neutral-200 px-4 py-2 text-neutral-600"
            >
              <span className="flex gap-2 text-primary-600 group-hover:underline">
                <ArrowPathRoundedSquareIcon className="h-5 w-5" />
                <TranslationsText textKey="searchselect.more.button" />
              </span>

              <TranslationsText
                textKey="searchselect.more.information"
                values={{ length: optionsCount }}
              />
            </button>
          )}
        </FloatingList>
      )}
    </div>
  );
}

interface TrailingToolsProps {
  loading?: boolean;
  clearable?: boolean;
  hasClearableValue: boolean;
  hasError?: boolean;
  disabled?: boolean;
  handleXClick: (event: MouseEvent) => void;
}

export function TrailingTools(props: TrailingToolsProps) {
  const {
    loading,
    clearable,
    hasClearableValue,
    hasError,
    disabled,
    handleXClick,
  } = props;

  return (
    <div className="inline-flex cursor-default flex-row items-center text-neutral-400">
      {loading && <Spinner className="mr-1 h-5 w-5 text-neutral-400" />}
      {clearable && hasClearableValue && !disabled && (
        <XMarkIcon
          className="h-4 w-4 hover:text-neutral-500"
          onClick={handleXClick}
        />
      )}
      {/* font-mono so the vertical bar is vertically aligned with the SVG */}
      <span className="mx-1 font-mono font-light">{` | `}</span>
      <ChevronDownIcon
        className={clsx({
          'h-5 w-5': true,
          'hover:text-neutral-500': !disabled,
        })}
        onMouseDown={preventDefault}
      />
      {hasError && <InputErrorIcon />}
    </div>
  );
}
