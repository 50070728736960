import { ListBulletIcon } from '@heroicons/react/16/solid';

import { TooltipPluginElement } from '../types';

import ListPlugin from './utils/ListPlugin';

export default function UnorderedPlugin(): TooltipPluginElement {
  return (
    <ListPlugin pluginKey="ul" icon={<ListBulletIcon className="h-4 w-4" />} />
  );
}
